.block-text {
    margin: 0 auto;
    max-width: var(--width-text);
    padding-left: var(--block-space-horizontal);
    padding-right: var(--block-space-horizontal);
}
.block-text .content {
    margin: 0 auto;
    max-width: var(--width-text);
    padding: 2rem;
    /*padding: var(--block-space-vertical-small) var(--block-space-horizontal);*/
}
.block-text .content h2 {
    margin-bottom: 2rem;
}
.block-text .content h3, .block-text .content h4, .block-text .content h5{
    margin-bottom: 1rem;
}

.block-text .content h6 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.block-text .content button {
    margin-top: var(--default-spacing);
}

.block-text .content-full-width {
    max-width: var(--width-main); /* This is 90rem (main content size) */
    /*max-width: var(--width-full);*/ /* This is 100% */
}
.block-text table {
    width: 100%;
    border-collapse: collapse;
    /*text-align: center;*/
    border-color: var(--color-grey) !important;
}

.block-text table, .block-text th, .block-text td {
    border: 1px solid;
}
.block-text table, .block-text th, .block-text td p{
    margin: 0;
}

.block-text th, .block-text td {
    padding: .5rem;
}
.block-text .contentTitle {
    color: black;
}

.block-text .no-background-spacing {
    padding: 2rem 0 0 0;
}

.block-text figure {
    margin-bottom: 1rem;
}

.block-text .content p:last-of-type, .block-text figure:last-of-type {
    margin-bottom: 0;
}

.block-text .no-background-spacing-all-around {
    padding: 0;
}

/*var(--color-grey-light)*/